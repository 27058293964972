.container-media {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
  margin-bottom: 30px;
}

.container-media > img {
  margin-left: 0px;
  width: 480px;
  align-self: baseline;
}

div.container-media > p {
  margin-left: 80px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1029px) {
  .container-media > img {
    margin-top: 0px;
    margin-left: 20px;
  }
  .App div.container-media > p {
    margin-left: 80px;
  }
}

@media only screen and (max-width: 949px) {
  .container-media > img {
    margin: auto;
    margin-bottom: 30px;
  }
  .container-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: center;
  }
  .App div.container-media > p {
    margin: 20px;
    margin-top: 0;
  }
}

@media only screen and (max-width: 499px) {
  .container-media > img {
    width: 100%;
  }
}
