.hero-container {
  margin-bottom: 30px;
  width: 100%;
}

.hero-container > .lazy-load-image-background {
  width: 100%;
}

.hero-container.ratio-4x3 {
  aspect-ratio: 4 / 3;
}

.hero-container.ratio-16x9 {
  aspect-ratio: 16 / 9;
}

.hero-container.ratio-2x1 {
  aspect-ratio: 2 / 1;
}

.hero-image {
  width: 100%;
}
