body .App .chapter-name {
  background-color: #170547;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 72px;
  color: white;
  padding-bottom: 50px;

  font-family: "Frank Ruhl Libre", serif;
}

.chapter-name h1 {
  font-size: 72px;
}

@media only screen and (max-width: 574px) {
  .chapter-name h1 {
    font-size: 46px;
  }
}
